/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // init Foundation JavaScript

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;



        if ($('.marquee3k').length){
          setTimeout(function () {
            Marquee3k.init();
          }, 1000);
        }

        function all() {


          function getSliderSettings_product() {
            return {
              infinite: true,
              speed: 1000,
              prevArrow: $('.custom-left-arrow'),
              nextArrow: $('.custom-right-arrow'),
              arrows: true,
              loop: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
              //centerMode: true,
              responsive: [
                {



                  breakpoint: 1024,
                  settings: {
                    infinite: true,
                    speed: 1000,
                    centerPadding: '12.5px',
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    //  variableWidth: true,
                    prevArrow: $('.custom-left-arrow'),
                    nextArrow: $('.custom-right-arrow'),
                    arrows: true,
                    dots: true,
                  }
                }
              ]
            }
          }



          $('.slider-product').slick(getSliderSettings_product());



          $('.single_add_to_cart_button').on('click', function(e){


            e.preventDefault();



            $( '.mini-cart').removeClass("hidden");


            $thisbutton = $(this),

                $form = $thisbutton.closest('form.cart'),

                id = $thisbutton.val(),

                product_qty = $form.find('input[name=quantity]').val() || 1,

                product_id = $form.find('input[name=product_id]').val() || id,

                variation_id = $form.find('input[name=variation_id]').val() || 0;

            var data = {

              action: 'ql_woocommerce_ajax_add_to_cart',

              product_id: product_id,

              product_sku: '',

              quantity: product_qty,

              variation_id: variation_id,

            };

            $.ajax({

              type: 'post',

              url: wc_add_to_cart_params.ajax_url,

              data: data,

              beforeSend: function (response) {

                $thisbutton.removeClass('added').addClass('loading');

              },

              complete: function (response) {

                $thisbutton.addClass('added').removeClass('loading');

              },

              success: function (response) {

                if (response.error & response.product_url) {

                  window.location = response.product_url;

                  return;

                } else {

                  $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);

                }

              },

            });

          });



          $(".close-mini-cart").click(function() {
            $( '.mini-cart').addClass("hidden");
          });


          $(".share-hover").click(function() {
            $( '.share-buttons').addClass("active");
          });

          $('form.cart').on( 'click', 'button.plus, button.minus', function() {

            // Get current quantity values
            var qty = $( this ).closest( 'form.cart' ).find( '.qty' );
            var val   = parseFloat(qty.val());
            var max = parseFloat(qty.attr( 'max' ));
            var min = parseFloat(qty.attr( 'min' ));
            var step = parseFloat(qty.attr( 'step' ));

            // Change the value if plus or minus
            if ( $( this ).is( '.plus' ) ) {
              if ( max && ( max <= val ) ) {
                qty.val( max );
              } else {
                qty.val( val + step );
              }
            } else {
              if ( min && ( min >= val ) ) {
                qty.val( min );
              } else if ( val > 1 ) {
                qty.val( val - step );
              }
            }

          });




          /* $(".services__main__navigation ul a").click(function () {


             $(document.body).animate({
               'scrollTop':   $('.services__main__wrapper'').offset().top
             }, 1000);
 */













          //image slider

          var swiperOptions = {
            loop: true,
            speed: 0,
            slidesPerView: 1,
            centeredSlides: true,
           effect: 'fade',
            allowTouchMove: false,
            fadeEffect: {
            crossFade: true,
              speed: 0,
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'custom',
              renderCustom: function (swiper, current, total) {
                return ('0' + current).slice(-2) + '–' + ('0' + total).slice(-2);
              }
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },

            on: {
              touchEnd (s, e) {
                let range = 5
                let diff = s.touches.diff = s.isHorizontal() ? s.touches.currentX - s.touches.startX : s.touches.currentY - s.touches.startY
                if (diff < range || diff > -range) s.allowClick = true
              },
              init: function () {
                console.log('swiper initialized');
                var currentVideo =  $("[data-swiper-slide-index=" + this.realIndex + "]").find("video");
                currentVideo.trigger('play');
              },
            }
          };


          var swiper = new Swiper(".swiper-container", swiperOptions);

          /* GET ALL VIDEOS */
          var sliderVideos = $(".swiper-slide video");

          /* SWIPER API - Event will be fired after animation to other slide (next or previous) */
          swiper.on('slideChange', function () {
            console.log('slide changed');
            /* stop all videos (currentTime buggy without this loop idea - no "real" previousIndex) */
            sliderVideos.each(function( index ) {
              this.currentTime = 0;
            });

            /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
            var prevVideo =  $("[data-swiper-slide-index=" + this.previousIndex + "]").find("video");
            var currentVideo =  $("[data-swiper-slide-index=" + this.realIndex + "]").find("video");
            prevVideo.trigger('stop');
            currentVideo.trigger('play');
          });

          /*
          swiper.on('touchStart', function () {
            $('.swiper-slide, .custom__cursor__outer').addClass('shrink');
          });

          swiper.on('touchEnd', function () {
            $('.swiper-slide, .custom__cursor__outer').removeClass('shrink');
          });

        */
/*
          const updateProperties = (elem, state) => {
            elem.style.setProperty('--x', `${state.x}px`)
            elem.style.setProperty('--y', `${state.y}px`)
            elem.style.setProperty('--width', `${state.width}px`)
            elem.style.setProperty('--height', `${state.height}px`)
            elem.style.setProperty('--radius', state.radius)
            elem.style.setProperty('--scale', state.scale)
          }

          document.querySelectorAll('.cursor').forEach(cursor => {
            let onElement

            const createState = e => {
              const defaultState = {
                x: e.clientX,
                y: e.clientY,
                width: 40,
                height: 40,
                radius: '50%'
              }

              const computedState = {}

              if (onElement != null) {
                const { top, left, width, height } = onElement.getBoundingClientRect()
                const radius = window.getComputedStyle(onElement).borderTopLeftRadius

                computedState.x = left + width / 2
                computedState.y = top + height / 2
                computedState.width = width
                computedState.height = height
                computedState.radius = radius
              }

              return {
                ...defaultState,
                ...computedState
              }
            }

            document.addEventListener('mousemove', e => {
              const state = createState(e)
              updateProperties(cursor, state)
            })


          })*/

          const shop_trigger = document.querySelector('.shop__trigger');
          const shop_nav_items = document.querySelector('.shop__trigger__items');
          const nav = document.querySelector('nav');

          const mobile_trigger = document.querySelector('.mobile-trigger');
          const nav_mobile = document.querySelector('.nav__mobile__shop');







          $('.index__item a').hover(
              function () {
                $('.index__item a').addClass('inactive');
                $(this).removeClass('inactive');

              }, function () {
                $('.index__item a').removeClass('inactive');
              }
          );

          $('.nav__item:not(:last-child) a').hover(
              function () {
                $('.home__wrapper__image').addClass('inactive');
                $('.nav__item a, .shop__trigger').addClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').addClass('inactive');
                $(this).removeClass('inactive');

              }, function () {
                $('.home__wrapper__image').removeClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').removeClass('inactive');
                $('.nav__item a, .shop__trigger').removeClass('inactive');
              }
          );

          $('.shop__trigger ').hover(
              function () {
                $('.home__wrapper__image').addClass('inactive');
                $('.nav__item a, .shop__trigger').addClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').addClass('inactive');
                $(this).removeClass('inactive');
                $('.shop__trigger__items a').addClass('active-shop-inner');

              }, function () {
                $('.home__wrapper__image').removeClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').removeClass('inactive');
                $('.nav__item a, .shop__trigger').removeClass('inactive');
                $('.shop__trigger__items a').removeClass('active-shop-inner');
              }
          );




          $('.shop__trigger__items a').hover(
              function () {

                $('.home__wrapper__image').addClass('inactive');
                $('.nav__item a').addClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').addClass('inactive');
                $(this).removeClass('inactive');


              }, function () {
                $('.home__wrapper__image').removeClass('inactive');
                $('.index__wrapper, .index__shop__wrapper').removeClass('inactive');
                $('.nav__item a').removeClass('inactive');
              }
          );

          $('.nav__item__last a').hover(
              function () {

                $('.home__wrapper__image').addClass('inactive');
                $('.index__wrapper').addClass('active');
                $('.nav__item a, .shop__trigger').addClass('inactive');
                $(this).removeClass('inactive');


              }, function () {
                $('.index__wrapper').removeClass('active');
                $('.home__wrapper__image').removeClass('inactive');
                $('.nav__item a, .shop__trigger').removeClass('inactive');
              }
          );

















        }



        setTimeout(function () {
          AOS.init({
            duration: 1000,
          });
        }, 300);

        all();









        barba.use(barbaCss);
        barba.init({


          transitions: [

            {

              beforeLeave: function (data) {

              },

              beforeEnter: function (data) {
                $('.project__navigation').addClass('hidden');


                all();

                AOS.init({
                  duration: 2000,
                });







              },
              afterEnter: function (data) {


              },


              leave: function (data) {
              },
              enter: function (data) {
              },
            },
          ],
        });









        barba.hooks.afterLeave((data) => {
          // Set <body> classes for "next" page
          var nextHtml = data.next.html;
          var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml);
          var bodyClasses = $(response).filter('notbody').attr('class');
          $("body").attr("class", bodyClasses);

        });

        barba.hooks.afterEnter((data) => {
        });


        barba.hooks.enter(() => {

          document.querySelector(".mobile-trigger").classList.remove('active');
          document.querySelector(".nav__mobile__shop").classList.remove('active');
          $('.main, .swiper-slide, video, img').removeClass('menu-open-hide-item');
          $('header, .contact, footer').removeClass('active-on-mobile');


          window.scrollTo(0, 0);
        });














        // fire initial resize
        $(window).trigger('resize');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      },
      resize: function() {
















      },
      windowload: function() {
        //console.log('loaded');
        $('html').addClass('window-loaded');
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {

      },
      windowload: function() {

      }
    },
    'page_template_template_layouts': {
      init: function() {

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on("load",UTIL.windowLoadEvents);

  // Resize Events
  var trackWindowWidth = $(window).width();

  $(window).resize(function (trackResizeEvent) {
    //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');

    // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
    if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
      // Update the window width for next time
      trackWindowWidth = $(window).width();

      UTIL.resizeEvents();
    }
  });


})(jQuery); // Fully reference jQuery after this point.